<template>
  <b-overlay
    :show="over"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card">
        <div class="p-1">
          <div class="h4 mb-1">
            รายการพันธมิตร
          </div>

          <div class="d-flex flex-wrap">
            <div class="d-flex align-items-start py-50">
              <div class="btn-disable">
                วันที่สมัคร(จาก)
              </div>

              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                style="width: 120px;"
                :config="{ maxDate: new Date().toISOString().split('T')[0] }"
              />
            </div>

            <div class="d-flex align-items-start py-50">
              <div class="btn-disable">
                วันที่สมัคร(ถึง)
              </div>

              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
                style="width: 120px;"
                :config="{ maxDate: new Date().toISOString().split('T')[0] }"
              />
            </div>

            <!-- <div class="d-flex align-items-start py-50">
              <div
                class="btn-disable"
              >
                ประเภท
              </div>
              <b-form-select
                v-model="searchType"
                :options="search_type_option"
                style="width: 120px;"
              />
            </div> -->

            <div class="d-flex align-items-start py-50">
              <div class="btn-disable">
                คำค้นหา
              </div>
              <input
                id="username"
                v-model="search_val"
                type="text"
                class="form-control"
                style="width: 230px"
                placeholder="กรอกข้อมูล"
              >
            </div>

            <div class="d-flex align-items-start py-50">
              <button
                class="btn btn-primary ml-lg-25"
                @click="GetList()"
              >
                ค้นหา
              </button>

              <button
                class="btn btn-warning ml-25"
                @click="resetzean()"
              >
                ซิ้งเซียน
              </button>
            </div>

            <div class="ml-auto py-50">
              <router-link :to="{ name: 'zean-add' }">
                <button
                  class="btn btn-primary"
                  @click="getUsers()"
                >
                  CREATE
                </button>
              </router-link>
            </div>
          </div>

        </div>

        <b-table
          small
          hover
          show-empty
          responsive
          :items="itemsTest"
          :fields="fields"
        >
          <template #empty="scope">
            <div class="p-2 text-center">
              <img
                src="/box.png"
                alt="empty"
                height="40px"
              > {{ scope.emptyFiltered ? 'No matching records found' :
                'ไม่พบข้อมูล' }}
            </div>
          </template>

          <template #cell(bank)="data">
            <div
              :id="data"
              class="text-center"
            >
              <img
                :src="`/bankIcon/${data.item.path_photo}`"
                alt="bankIcon"
                height="25"
                width="25"
                class="rounded-circle"
              >
              <br>
              <small>{{ data.item.acc_no }}</small>
            </div>
          </template>
          <template #cell(invite_code)="data">
            <!-- {{ $store.state.app.agent }} -->
            <div v-if="$store.state.app.agent">
              <b-button
                v-clipboard:copy="`${$store.state.app.agent.site}/register?ref=${data.item.invite_code}`"
                variant="info"
                size="sm"
                class="btn-icon"
                @click="$swal({
                  icon: 'success',
                  text: 'คัดลอกสำเร็จ',
                })"
              >
                คัดลอก
              </b-button>
            </div>

          </template>

          <template #cell(payment_amount)="data">
            <button
              class="btn btn-sm btn-success"
              @click="$router.push({ name: 'commission', params: { id: data.item.invite_code } })"
            >
              Commission
            </button>
            <button
              class="btn btn-sm ml-25 mt-25"
              style="background-color:#1dccbc;"
              @click="$router.push({ name: 'commission_kr', params: { id: data.item.invite_code } })"
            >
              Commission KR
            </button>
          </template>

          <template #cell(uesr_all)="data">
            <button
              class="btn btn-sm btn-primary text-nowrap"
              @click="$router.push({ name: 'reportmember_register', params: { id: data.item.invite_code } })"
            >
              {{ Commas(data.item.ref_total) }} คน : ข้อมูลรายงาน
            </button>
          </template>

          <template #cell(summary_all)="data">
            <button
              class="btn btn-sm btn-warning text-nowrap"
              @click="$router.push({ name: 'reportmember', params: { id: data.item.invite_code } })"
            >
              ข้อมูลรายงาน
            </button>
            <button
              class="btn btn-sm text-nowrap ml-25 mt-25"
              style="background-color:#ffd97e;"
              @click="$router.push({ name: 'reportmember_kr', params: { id: data.item.invite_code } })"
            >
              ข้อมูลรายงาน KR
            </button>
          </template>

          <template #cell(summary_play_all)="data">
            <button
              class="btn btn-sm btn-warning text-nowrap"
              @click="$router.push({ name: 'reportmember_totalplay', params: { id: data.item.invite_code } })"
            >
              ข้อมูลรายงาน
            </button>
          </template>
        </b-table>
        <div class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1 pl-1 pb-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="GetList()"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pr-1 pt-1"
              @input="GetList()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>
  </b-overlay>
</template>

<script>
import {
  BButton, BTable, BFormSelect, BFormGroup,
  BPagination, BSpinner,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default {
  components: {
    BButton,
    BTable,
    BFormSelect,
    BFormGroup,
    BPagination,
    flatPickr,
    BSpinner,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      search_type_option: [
        { value: null, text: 'ประเภทค้นหา' },
        { value: 'name', text: 'ชื่อ' },
        { value: 'username', text: 'ยูสเซอร์เนม' },
        { value: 'tel', text: 'เบอร์โทร' },
      ],
      over: false,
      searchType: null,
      searchKeyword: null,
      dateStart: new Date().toISOString().split('T')[0],
      dateEnd: new Date().toISOString().split('T')[0],
      perPage: 30,
      pageOptions: [30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      search_val: '',
      sortDesc: false,
      sortDirection: 'asc',
      UserData: JSON.parse(localStorage.getItem('userData')),
      searchBy: 'name',
      searchByOptions: [
        { value: 'name', text: 'ชื่อ' },
        { value: 'email', text: 'อีเมล' },
        { value: 'phone', text: 'เบอร์โทร' },
      ],
      fields: [
        { key: 'tel', label: 'เบอร์มือถือ' },
        { key: 'fullname', label: 'ชื่อ-นามสกุล' },
        { key: 'invite_code', label: 'ลิงก์การตลาด' },
        // { key: 'bank', label: 'ธนาคาร', thClass: 'text-center' },
        {
          key: 'created_at',
          label: 'วันที่สมัคร',
          formatter: value => moment(value)
            .tz('Asia/Bangkok')
            .format('YYYY-MM-DD HH:mm'),
        },
        {
          key: 'payment_amount', label: 'ยอดชำระ', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'uesr_all', label: 'ยูสภายใต้', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'summary_all', label: 'สรุปฝาก-ถอนยูส', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'summary_play_all', label: 'สรุปยอดเล่นยูส', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      // fields: [
      //   { key: 'action', label: '' },
      //   { key: 'name', label: 'ผู้ใช้งาน' },
      //   { key: 'username', label: 'USERNAME' },
      //   { key: 'created_at', label: 'สมัครเมื่อ' },
      //   { key: 'SumAll', label: 'สมัครวันนี้' },
      //   { key: 'SumDep', label: 'สมัครและฝากวันนี้' },
      //   { key: 'Show_profit', label: 'กำไร(Real Time)' },
      // ],
      items: [],
      itemsTest: [],
      userlist: [],
      searchlist: [
        { value: 1, text: 'ชื่อ' },
        { value: 2, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
      ],
      Show_profit: 0,
    }
  },
  mounted() {
    this.GetList()
  },
  methods: {
    async GetList() {
      const obj = {
        start_date: this.dateStart,
        end_date: this.dateEnd,
        page: this.currentPage,
        per_page: this.perPage,
        searchType: this.searchType, // name,username,tel
        searchKeyword: this.search_val,
      }
      this.over = true
      try {
        const { data } = await this.$http.post('/zean/list', obj)
        // console.log(data)
        this.totalRows = data.total
        this.itemsTest = data.data
        this.over = false
      } catch (e) {
        console.log(e)
        this.over = false
      }
    },
    GetList_bk() {
      let index = 0
      const params = {
        searchType: this.searchType,
        searchKeyword: this.searchKeyword,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http
        .post('/zean/index/all', params)
        .then(response => {
          this.userlist = response.data.data
          this.userlist.forEach(items => {
            this.userlist[index].SumAll = null
            this.userlist[index].SumDep = null
            this.userlist[index].win_lose = null
            this.userlist[index].Show_profit = null
            this.getregis_dep(items.invite_code, index)
            this.GetdetailWinlose(items.invite_code, index)
            index += 1
          })
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    GetdetailWinlose(invite, index) {
      const params = {
        invite_code: invite,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/zean/getdetailWinlose', params)
        .then(response => {
          this.userlist[index].Show_profit = response.data.profit
        })
        .catch(error => console.log(error))
    },
    getregis_dep(invite, index) {
      const params = {
        invite_code: invite,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/zean/getregis_dep', params)
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.userlist[index].SumAll = response.data.SumAll
          this.userlist[index].SumDep = response.data.SumDep
          this.userlist[index].win_lose = response.data.sumwl
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    GoToDetail(id, username) {
      this.$router.push({ name: 'invite-detail-byadmin', params: { id, username } })
    },
    GoToEdit(id) {
      this.$router.push({ name: 'Deposit-Withdraw', params: { id } })
    },
    async resetzean() {
      this.over = true
      try {
        const { data: response } = await this.$http.get('/zean/resetzean')
        this.over = false
        console.log(response.data)
      } catch (error) {
        console.log(error)
        this.over = false
      }
    },
  },
}
</script>

<style scoped>
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, .42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.search-1 {
  padding: 1rem;
  background: #000;
}

thead th {
  background: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
